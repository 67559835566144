<template>
	<v-card>
		<v-card-title>
			<v-row>
				<v-col cols="6">Device Transaction History</v-col>
				<v-col cols="4">
					<v-menu
						ref="menu"
						v-model="openDatePicker"
						:close-on-content-click="false"
						:value.sync="historyDate"
						transition="scale-transition"
						offset-y
						min-width="200px"
					>
						<template v-slot:activator="{ on }">
							<v-text-field v-model="historyDate" label="Select Date" prepend-icon="event" readonly v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="historyDate" no-title scrollable :max="getDate()">
							<div class="flex-grow-1"></div>
							<v-btn text color="primary" @click="openDatePicker = false">Cancel</v-btn>
							<v-btn text color="accent" @click="fetchHistory(historyDate)">OK</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="2" class="text-right">
					<v-btn :disabled="!isToday" rounded @click="refresh()">
						<v-icon>refresh</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>

		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<v-simple-table>
						<template>
							<thead>
								<tr>
									<th class="text-center">Total Transactions</th>
									<th class="text-center">Total Qty Success</th>
									<th class="text-center">Total Amount Success</th>
									<th class="text-center">Total Qty Failed</th>
									<th class="text-center">Total Amount Failed</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-center">
										<v-chip color="accent">{{ deviceHistory.TotalTransactions }}</v-chip>
									</td>
									<td class="text-center">
										<v-chip color="green" class="white--text">{{ deviceHistory.TotalQtySuccess }}</v-chip>
									</td>
									<td class="text-center">
										<v-chip color="green" class="white--text">{{ formatCurrency(deviceHistory.TotalAmountSuccess) }}</v-chip>
									</td>
									<td class="text-center">
										<v-chip color="red" class="white--text">{{ deviceHistory.TotalQtyFailed }}</v-chip>
									</td>
									<td class="text-center">
										<v-chip color="red" class="white--text">{{ formatCurrency(deviceHistory.TotalAmountFailed) }}</v-chip>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table ref="dataTable" :headers="headers" :items="deviceHistory.CardTransactions" :loading="status.loading" class="elevation-1">
						<template v-slot:item="{ item }">
							<tr :class="item.status == 300 ? 'green' : 'red'" class="lighten-1 white--text`" style="border-bottom: 1px solid">
								<td class="text-center" style="border-bottom: 1px solid rgba(255, 255, 255, 0.3)">{{ formatCurrency(item.amount, true) }}</td>
								<td class="text-center" style="border-bottom: 1px solid rgba(255, 255, 255, 0.3)">{{ item.time }}</td>
								<td class="text-center pt-2 pb-2" style="border-bottom: 1px solid rgba(255, 255, 255, 0.3)">
									<v-chip>{{ getStatus(item.status) }}</v-chip>
								</td>
								<td class="text-center" style="border-bottom: 1px solid rgba(255, 255, 255, 0.3)">{{ item.reason }}</td>
								<td class="text-center" style="border-bottom: 1px solid rgba(255, 255, 255, 0.3)">{{ item.description }}</td>
							</tr>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "OrganisationsDevice",

	computed: {
		selectedDevice: function() {
			return this.$store.state.devices.selectedDevice;
		},
		deviceHistory: function() {
			return this.$store.state.devices.deviceHistory;
		},
		status: function() {
			return this.$store.state.devices.status;
		}
	},

	data: function() {
		return {
			headers: [
				{ text: "Amount", value: "amount", align: "center", sortable: false },
				{ text: "Time", value: "time", align: "center", sortable: false },
				{ text: "Status", value: "status", align: "center", width: "200" },
				{ text: "Reason", value: "code", align: "center" },
				{ text: "Description", value: "description", align: "center" }
			],
			openDatePicker: false,
			historyDate: String,
			today: String,
			isToday: Boolean
		};
	},

	mounted: function() {
		if (this.selectedDevice == 0) {
			// return to profile
			this.$router.push({ name: `organisation-devices` });
		} else {
			this.$data.historyDate = this.getDate();
			this.$data.today = this.getDate();
		}
	},

	methods: {
		getDate() {
			let date = Date.now();
			let d = new Date(date),
				month = "" + (d.getMonth() + 1),
				day = "" + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;

			let historyDate = [year, month, day].join("-");
			return historyDate;
		},
		async fetchHistory(date) {
			if (this.$data.today === date) {
				this.$data.isToday = true;
			} else {
				this.$data.isToday = false;
			}

			this.$data.historyDate = date;
			this.$data.openDatePicker = false;
			let params = { id: this.selectedDevice, date: date };

			await this.$store.dispatch("devices/fetchLinkedDeviceHistory", params);
		},
		async refresh() {
			let date = Date.now();
			let d = new Date(date),
				month = "" + (d.getMonth() + 1),
				day = "" + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;

			let historyDate = [year, month, day].join("-");

			let params = { id: this.selectedDevice, date: historyDate };

			await this.$store.dispatch("devices/fetchLinkedDeviceHistory", params);
		},
		getStatus(item) {
			let status = "";
			switch (item) {
				case 0:
					status = "Created";
					break;
				case 100:
					status = "Invalid";
					break;
				case 200:
					status = "Result Received";
					break;
				case 250:
					status = "Card Error";
					break;
				case 251:
					status = "No Amount";
					break;
				case 252:
					status = "Amount Mismatch";
					break;
				case 300:
					status = "Recharged";
					break;
				case 400:
					status = "Recharge Failed";
					break;
			}
			return status;
		},
		formatCurrency(val, cents) {
			if (cents) val = val / 100;
			return Utils.formatText(val.toString(), Utils.TextType.CURRENCY);
		}
	}
});
</script>

<style></style>
